import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { NotFoundPage } from '~/components/404';

const NotFound: React.FC<PageProps> = props => (
  <>
    <SEO title="404.pageTitle" pageContext={props.pageContext} />
    <NotFoundPage {...props} />
  </>
);

export default NotFound;
