import styled from 'styled-components';
import { Typography, Grid as MuiGrid } from '@material-ui/core';
import breakpoint from '~/common/breakpoint';

export const GridContainer = styled(MuiGrid).attrs({
  container: true,
  justify: 'center',
  alignItems: 'center',
})`
  margin-top: 3rem;
  flex-direction: column;
  min-height: 300px;
  ${breakpoint.md`
    flex-direction: row;
  `}
`;

export const Grid = styled(MuiGrid).attrs({
  item: true,
})`
  & + & {
    margin: 0.75rem 0 0;
    padding: 1rem 0 0;
    text-align: center;
    border-top: 1px solid ${props => props.theme.palette.divider};
    ${breakpoint.md`
      margin: 0 0 0 2rem;
      padding: 0 0 0 2rem;
      text-align: left;
      border-top: 0;
      border-left: 1px solid ${props => props.theme.palette.divider};
    `}
  }
`;

export const Heading = styled(Typography).attrs({ variant: 'h1' })`
  font-size: 2.75rem;
  ${breakpoint.md`
    font-size: 3rem;
    text-align: right;
  `}
`;

export const Subheading = styled(Typography).attrs({ variant: 'h2' })`
  font-size: 1.75rem;
  ${breakpoint.md`
    font-size: 2rem;
  `}
`;

export const Paragraph = styled(Typography)`
  margin: 1rem 0;
`;
