import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageShell } from '~/components/BaseElements/pageShell';
import { Container } from '@material-ui/core';
import * as Styled from './styles';

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageShell>
      <Container>
        <Styled.GridContainer>
          <Styled.Grid>
            <Styled.Heading>{t('404.pageTitle')}</Styled.Heading>
          </Styled.Grid>
          <Styled.Grid>
            <Styled.Subheading>{t('404.title')}</Styled.Subheading>
            <Styled.Paragraph>{t('404.p1')}</Styled.Paragraph>
          </Styled.Grid>
        </Styled.GridContainer>
      </Container>
    </PageShell>
  );
};
